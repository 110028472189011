<template>
  <div style="display: flex; flex-direction: column">
    <div
      v-for="(button, buttonIndex) in message.data.content[0].buttons"
      :key="`button-${buttonIndex}`"
    >
      <el-button
        v-if="
          !((button.type && button.type === 'website') || button.url) || isDisabledButton(button)
        "
        plain
        v-html="button.text"
        size="small"
        class="message-bubble-button"
        :class="{ 'blue-button': buttonOutline === 'primary' }"
        style="width: 100%; margin: 0 0 10px 0; white-space: normal"
        :style="{
          fontFamily: settings.font,
        }"
        :type="buttonOutline"
        :disabled="isDisabledButton(button)"
        @click="onClick($event, button)"
      ></el-button>
      <a v-else :href="validUrl(button.url)" :target="button.popup ?? '_blank'">
        <el-button
          plain
          v-html="button.text"
          size="small"
          class="message-bubble-button"
          :class="{ 'blue-button': buttonOutline === 'primary' }"
          style="width: 100%; margin: 0 0 10px 0; white-space: normal"
          :style="{
            fontFamily: settings.font,
          }"
          :type="buttonOutline"
          @click="onClick($event, button)"
        ></el-button>
      </a>
    </div>
  </div>
</template>

<script>
import _ from "lodash-es";
import isDisabledButton from "@/helpers/isDisabledButton";
import validUrl from "@/helpers/validUrl";

export default {
  props: ["message", "buttonOutline", "shouldDisableIfInLivechat"],
  computed: {
    /**
     * @description Most of the webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },
  },
  methods: {
    validUrl,
    isDisabledButton(button) {
      return isDisabledButton(this, button);
    },
    onClick($event, button) {
      const isUpload =
        button.text.toLowerCase().indexOf("upload") > -1 &&
        button.text.toLowerCase() !== "upload documents";
      if (isUpload) {
        this.$root.$emit("upload", button);
      } else {
        const _button = {
          ...button,
          $event,
        };
        this.$emit("sendPostback", _button);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-beta {
  .message-bubble-button {
    // width: auto !important;
    word-wrap: break-word;
    font-size: 12px !important;
    border-radius: 8px;
    line-height: 16px;

    &.blue-button {
      border: 1px solid #4e6cce;
      color: #4e6cce;
      background: #fff;

      &:hover {
        color: #fff;
        background: #4e6cce;
      }
    }
  }
}
</style>
