import Vue from "vue";
import "element-ui/lib/theme-chalk/index.css";
import Vuex, { StoreOptions } from "vuex";

import * as getters from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

// @ts-ignore
import Viewer from "v-viewer";

Vue.use(Viewer);
Vue.use(Vuex);

import { RootState } from "./types";

const state: RootState = {
  id: "",
  user: {},
  jwtDrawer: {
    enabled: false,
    cancel: false,
    iconType: "warning",
    title: "Notification!",
    text: "",
    action: window.kr_settings.nativeAction || function () {},
  },
  uid: localStorage.getItem("_kruid") || "",
  page_id: "",
  token: localStorage.getItem("_krtoken") || "",
  webchatUsersIsAtMaxLimit: false,
  newUser: true,
  simulateUser: false,
  greeted: false,
  server: "",
  sessionExpired: false,
  sessionExpiryTimeout: null,
  webviewUrl: "",
  showWebview: false,
  returnToChatButton: false,
  socketConnectionStatus: false,

  isInitiating: true,

  isSendingPostback: false,

  initialState: {},
  chatWindow: false,
  facebookChatWindow: false,
  online: navigator.onLine,
  lightbox: false,
  messages: [],
  // trending topic
  trendingTopics: [],
  currentLanguage: "",

  fuse: undefined,
  socket: null,

  messagePreview: "",
  showMessagePreview: false,
  displayLauncher: false,
  disableTextInput: false,
  settings: {
    // trending topic setting
    trendingTopicBanner: "",
    trendingTopicTitle: "",
    defaultLanguage: "",
    fileUploadStorageType: "",
    session_length: 30,
    title: "",
    font: "",
    resume: false,
    openAutomatically: false,
    showHistoryDownload: true,
    showTextbar: true,
    apps: {},
    subtext: {},
    facebook: {
      page_id: "",
      messenger_app_id: "",
    },
    liveChatStatus: false,
    typingIndicator: {
      status: false,
    },
    agentBubbleColor: "",
    previewBubble: {
      enabled: false,
      message: "",
      delay: 15,
      hide: 30,
      maxLimitReachedMessage:
        "We're experiencing high traffic. Please try again soon. Thank you for your patience!",
      persistPreviewMessageShown: false,
    },
    disableTextInputAfterLivechatSession: false,
    charLimit: { showCountDown: false, limit: 1000 },
    getToken: () => new Promise((resolve) => resolve("token?")),
    chatHistoryMode: "SESSION",
    stickyMenuConfig: {
      stickyMenuLanguages: [
        {
          lang: "en",
          value: "Go to menu",
        },
      ],
    },
    videoVAConfig: {
      subtitleFontSize: 16,
      enableAlphabetButton: true,
    },
    stickyMenu: {},
    stickyMenuLanguage: "en",
    ui_beta: false,
    rtlMode: false,
    widgetIconColor: "",
    widgetIconTextColor: "",
    headerModalIconColor: "",
    userMainBackgroundColor: "",
    userMainTitleColor: "",
  },
  allPastMessagesFetched: false,
  failedRequests: [],
  unreadChatsCount: 0,

  isEnabledNotification: false,
  documentTitle: document.title,
  emailDomainWhitelist: [],
  sessionEnded: false,
  sessionButtonReady: false,

  promptedCobrowseEnded: false,
  liveChatAgentName: "",
};

const store: StoreOptions<RootState> = {
  state,
  getters,
  actions,
  mutations,
};

export default new Vuex.Store<RootState>(store);
