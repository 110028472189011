import axios, { Method } from "axios";
import _ from "lodash-es";
import store from "../store";
import * as Sentry from "@sentry/browser";

let retries = 0;
const isJWTRegex = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

const logAxiosExceptionToSentry = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    Sentry.captureException(
      new Error(
        JSON.stringify({
          response: error?.response?.data,
          status: error?.response?.status,
          url: (error?.response?.config && error?.response?.config?.url) || "",
        })
      )
    );
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser
    // - see: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest
    // and an instance of http.ClientRequest in node.js
    Sentry.captureException(
      new Error(
        JSON.stringify({
          response: error?.request?.responseText || "",
          status: error?.request?.status,
          url: error?.request?.responseURL || "",
        })
      )
    );
  } else {
    // Something happened in setting up the request that triggered an Error
    Sentry.captureException(new Error(error.message));
  }
};

const showJWTErrorMessage = () => {
  store.commit("SET_NATIVE_ACTION", () => {
    window.top?.location.reload();
  });
  store.commit("JWT_MESSAGE", {
    title: "Unidentified",
    text: "Your generated token method is invalid. Please check the method or secret.",
  });
};

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const getTokenUrl = _.get(window, "kr_settings.getToken.url");
    const getTokenMethod = _.get(window, "kr_settings.getToken.method");
    const accessTokenPath = _.get(window, "kr_settings.getToken.accessTokenPath");
    const iosSDK = _.get(window, "kr_settings.appTokenConfigured");

    const forbidden = _.get(error, "response.status") === 403;
    const responseData = _.get(error, "response.data", {});
    const invalidSignature = responseData?.name === "JsonWebTokenError";
    const jwtExpired = responseData?.name === "TokenExpiredError";

    const jwtGeneralError = forbidden || invalidSignature || jwtExpired;

    if (!jwtGeneralError) {
      logAxiosExceptionToSentry(error);
      return Promise.reject(error);
    }

    // For auto generation of JWT + auto retry (AIASG)
    // Website
    if (isTokenConfigured(getTokenUrl, getTokenMethod)) {
      if (retries > 5) {
        retries = 0;
        showJWTErrorMessage();
        return Promise.reject(error);
      }
      // Refresh new token
      const jwt = await getNewToken(getTokenUrl, getTokenMethod, accessTokenPath);
      if (isJWTRegex.test(jwt)) {
        setTokenToAxiosAuthorizationHeader(jwt);
        const response = await recallErrorRequest(error, jwt);
        return response;
      } else {
        showJWTErrorMessage();
        return Promise.reject(error);
      }
    }
    // IPOS
    else if (iosSDK) {
      window.location.href = "keyreplysdk://?alert=jwtexpired";
      return {
        data: {
          jwtExpiredAppFlow: true,
          requestData: _.pick(error.response.config, ["method", "data", "url", "headers"]),
        },
      };
    }
    // For notifying JWT error when JWT is manually generated from external (Panin)
    else {
      if (invalidSignature) {
        store.commit("JWT_MESSAGE", {
          title: "Invalid Signature",
          text: "Your session has invalid signature. Please re-enter the valid one.",
        });
      } else if (jwtExpired) {
        store.commit("JWT_MESSAGE", {
          title: "Session Expired",
          text: "Your session has been expired. Please re-enter the new session.",
        });
      } else {
        store.commit("JWT_MESSAGE", {
          title: "Invalid",
          text: "Your session is invalid. Please re-enter the valid one.",
        });
      }
    }
  }
);

export const isTokenConfigured = (url: string, method: Method) => {
  return !!(url && method);
};

export const getNewToken = async (url: string, method: Method, accessTokenPath: string) => {
  const axiosAuthInstance = axios.create();
  delete axiosAuthInstance.defaults.headers.common["Authorization"];
  const response = await axiosAuthInstance({
    url,
    method,
  });

  if (accessTokenPath) {
    return _.get(response, accessTokenPath);
  }

  return response.data;
};

export const recallErrorRequest = async (error: any, token: string) => {
  retries++;
  Object.assign(error.response.config.headers, {
    Authorization: `Bearer ${token}`,
  });
  const { data, url, headers, method } = error.response.config;
  const response = await axios({
    method,
    data,
    url,
    headers,
  });

  return response;
};
export const setTokenToAxiosAuthorizationHeader = async (jwt: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};

export default axios;
