<template>
  <div
    class="video-container"
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 1)"
    key="main"
  >
    <div
      class="script-annotation"
      :class="{ sending: isLoadingInput }"
      :style="{
        fontSize: `${Math.min(videoVAConfig.subtitleFontSize, 24)}px`,
      }"
      v-if="(content.subtext || isLoadingInput) && content.options.isShowSubtitles"
    >
      <p>
        {{ isLoadingInput ? `${$store.getters.settings.title} is typing...` : selectedScript }}
      </p>
    </div>
    <div class="btn-minimized" @click="$emit('handleMinimizeVideo')">
      <i class="el-icon-arrow-down"></i>
    </div>
    <video
      :src="videoSource"
      id="video-content"
      @click="handleToggleVideo"
      @ended="handleToggleVideo"
      @loadedmetadata="handleVideoSuccessful"
      @error="handleVideoError"
      @timeupdate="handleUpdateScript"
      ref="videoContent"
      :muted="isMuted"
    />
    <el-tooltip content="Click to play" effect="dark" v-if="!isPlay">
      <div
        class="btn-pause"
        :style="{ 'background-image': 'url(img/icons/play-button-980x980.png)' }"
        @click="handleToggleVideo"
      ></div>
    </el-tooltip>
    <div
      class="button-container"
      @wheel.stop
      :style="!disableTextInput ? 'bottom: 55px' : 'bottom: 10px'"
    >
      <div
        v-for="(btn, index) in content.buttons"
        :key="index"
        class="button-overlay"
        ref="btnOverlay"
        @click="handleButtonNavigate(btn, index)"
      >
        <span v-if="videoVAConfig.enableAlphabetButton" class="choice">{{
          String.fromCharCode(index + 97).toUpperCase()
        }}</span>
        <span class="title">{{ btn.text }}</span>
      </div>
    </div>
    <div class="chat-input-wrapper" v-show="!disableTextInput && isInput">
      <input
        type="text"
        v-model="chatInput"
        placeholder="Type a message..."
        autocomplete="off"
        tabindex="-1"
        @keyup.enter="handleSendButton"
        @input="$emit('handleTypingMessage', chatInput)"
      />
      <i class="el-icon-s-promotion btn-send" @click="handleSendButton"></i>
    </div>
    <div class="mute-button-group">
      <div
        style="display: flex; align-items: center; justify-content: center"
        v-if="isMuted"
        @click="toggleMuted"
      >
        <img class="mute-btn" :src="muteIcon" alt="" />
      </div>
      <div
        style="display: flex; align-items: center; justify-content: center"
        v-else
        @click="toggleMuted"
      >
        <img class="unmute-btn" :src="unmuteIcon" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Video",
  props: {
    videoBotMessage: {
      type: Object,
      default: function () {
        return {};
      },
    },
    chatbox: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      isPlay: false,
      isMuted: false,
      loading: true,
      chatInput: "",
      isInput: true,
      isLoadingInput: false,
      arrayTranscriptions: [],
      timeChangeScript: this.$store.getters.settings.timeScriptVideo || 6,
      currentSentences: 0,
      muteIcon: "https://keyreply.blob.core.windows.net/files/images/mute.png",
      unmuteIcon: "https://keyreply.blob.core.windows.net/files/images/unmute.png",
    };
  },
  computed: {
    content() {
      return this.videoBotMessage.data.content[0];
    },
    disableTextInput() {
      return this.videoBotMessage.data.options?.disableTextInput;
    },
    selectedScript() {
      return this.arrayTranscriptions[this.currentSentences];
    },
    videoVAConfig() {
      return this.$store.state.settings.videoVAConfig;
    },
    videoSource() {
      return this.content?.url || this.content?.files[0];
    },
  },
  mounted() {
    this.chatInput = this.chatbox;
    console.log(this.content);
  },
  methods: {
    handleToggleVideo() {
      this.isPlay = !this.isPlay;
      this.isPlay ? this.$refs.videoContent.play() : this.$refs.videoContent.pause();
    },
    handleButtonNavigate(btn, index) {
      this.$refs.btnOverlay[index].classList.add("flicker");
      this.$emit("sendPostback", btn);
      setTimeout(() => {
        this.$emit("resetSelectedVideo");
        this.isPlay = false;
        this.$refs.videoContent?.pause();
        this.$refs.btnOverlay[index]?.classList?.remove("flicker");
      }, 1100);
    },
    handleVideoSuccessful() {
      this.loading = false;
      this.arrayTranscriptions = [];
      this.handleGenerateTranscriptions();
    },
    handleGenerateTranscriptions() {
      const durationTime = this.$refs.videoContent.duration;
      if (!this.content.subtext) {
        this.arrayTranscriptions = [];
        return;
      }
      if (Math.floor(durationTime) <= this.timeChangeScript) {
        this.arrayTranscriptions.push(this.content.subtext);
        return;
      }
      const words = this.handleSeparateWordBySpace(this.content.subtext);
      const wordInTimeScript = Math.ceil((words.length / durationTime) * this.timeChangeScript);
      const sentences = Math.ceil(words.length / wordInTimeScript) - 1;
      let count = 0;
      while (count <= sentences) {
        let next = count + 1;
        this.arrayTranscriptions.push(
          words.slice(count * wordInTimeScript, next * wordInTimeScript).join(" ")
        );
        count++;
      }
    },
    handleSeparateWordBySpace(sentences) {
      return sentences.split(" ");
    },
    handleVideoError(e) {
      console.log("error loading video", this.$refs.videoContent.error);
    },
    handleSendButton() {
      if (this.chatInput.trim().length === 0) return;
      this.isLoadingInput = true;
      this.$emit("sendMessage", { text: this.chatInput });
      this.$emit("resetSelectedVideo");
      this.chatInput = "";
      this.isInput = false;
      this.isLoadingInput = false;
    },
    handleUpdateScript(e) {
      const time = Math.floor(e.target.currentTime / this.timeChangeScript);
      if (time >= this.arrayTranscriptions.length) {
        this.currentSentences = time - 1;
        return;
      }
      if (this.currentSentences !== time) this.currentSentences = time;
    },
    toggleMuted() {
      this.isMuted = !this.isMuted;
    },
  },
};
</script>

<style scoped lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  .script-annotation {
    display: flex;
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 100%;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    background: #4e6cce;
    color: #fff;
    text-align: center;
    transform: translateY(-50px);
    animation: slide 1s 0.5s forwards;
    transition: background 0.2s, color 0.5s;
    z-index: 11;
    &.sending {
      background: #dce2f5;
      color: #a6b5e6;
    }
  }
  .btn-minimized {
    position: absolute;
    bottom: 25%;
    right: 2%;
    padding: 5px;
    background: #4e6cce;
    clip-path: circle();
    z-index: 99;
    opacity: 0.7;
    cursor: pointer;
    transition: all 0.2s ease-in;

    &:hover {
      opacity: 1;
    }
    i {
      font-size: 30px;
      color: #fff;
    }
  }
  #video-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  .btn-pause {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    background-size: cover;
    cursor: pointer;
    opacity: 0.85;
    transition: all 0.5s;
    transform: translate(-50%, -50%);
    z-index: 999;
    &:hover {
      width: 100px;
      height: 100px;
      opacity: 1;
    }
  }
  .button-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 75%;
    max-height: 220px;
    transform: translate(-50%, 0%);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #414649;
      border-radius: 10px;
    }
    z-index: 99;
    .button-overlay {
      display: flex;
      align-items: center;
      padding: 7px;
      margin: 10px;
      color: white;
      background-color: rgba(0, 0, 0, 0.4);
      border: 1px solid transparent;
      border-radius: 80px;
      cursor: pointer;
      opacity: 1;
      &:hover {
        border: 1px solid #4e6cce;
      }
      .choice {
        text-align: center;
        background-color: #4e6cce;
        clip-path: circle();
        padding: 8px;
      }
      .title {
        margin-left: 15px;
        font-size: 18px;
      }
    }
    .flicker {
      animation: fade infinite 0.6s;
    }
    @keyframes fade {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
        border: 1px solid #4e6cce;
      }
    }
    @keyframes slide {
      0% {
        transform: translateY(-50px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  .chat-input-wrapper {
    position: absolute;
    bottom: 10px;
    width: 85%;
    display: flex;
    box-shadow: 0 0 3px #fff;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px;
    z-index: 100;
    background-color: #f7f7f7;
    input {
      padding: 15px;
      font-size: 16px;
      border: 0;
      outline: 0;
      background-color: #f7f7f7;
      margin: 0;
      width: 85%;
      outline: none;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      &::placeholder {
        color: rgb(192, 192, 192);
      }
    }
    .btn-send {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f7f7f7;
      border: 1px transparent;
      cursor: pointer;
      color: #4e6cce;
      font-size: 25px;
      width: 15%;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
    }
  }
}

.mute-button-group {
  position: absolute;
  bottom: 35%;
  right: 2%;
  z-index: 99;
  cursor: pointer;
  background: #4e6cce;
  clip-path: circle();
  padding: 5px;
  opacity: 0.7;
  transition: all 0.2s ease-in;
  width: 40px;
  height: 40px;

  &:hover {
    opacity: 1;
  }
  .mute-btn,
  .unmute-btn {
    width: 24px;
    height: 24px;
    margin-top: 2px;
    transition: all 0.1s ease-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>
