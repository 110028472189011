<template>
  <div
    id="keyreply-container"
    :class="{
      'keyreply-left': settings.position === 'left',
      'keyreply-right': settings.position !== 'left',
      'keyreply-opened': !!$store.state.chatWindow,
      'ui-beta': isUiBetaEnabled,
    }"
  >
    <div
      class="keyreply-launcher-wrapper"
      id="keyreply-launcher"
      @mousedown="handleGrabElement({ event: $event, elementId: 'keyreply-launcher' })"
      @touchstart="handleGrabElement({ event: $event, elementId: 'keyreply-launcher' })"
    >
      <launcher
        ref="launcher"
        v-if="displayLauncher"
        @click.native="clickedLauncher"
        :isLauncherCanClick="!isElementReadyToDrag"
      />
      <app-icon
        v-for="(app, index) in visibleApps"
        :key="app.id"
        :show="true"
        :app="app"
        :data="app.data"
        :index="index"
      />
      <transition name="el-zoom-in-bottom">
        <el-card
          v-if="
            !chatWindow &&
            this.previewBubble.enabled &&
            this.showMessagePreview &&
            displayLauncher &&
            !$store.getters.webchatUsersIsAtMaxLimit
          "
          :style="{
            color: settings.agentTextColor,
            background: settings.agentBubbleColor,
          }"
          class="keyreply-popup-bubble"
        >
          <div @click="clickLauncher">
            <div
              id="closeButton1"
              style="position: relative; top: -10px; right: -10px; float: right; cursor: pointer"
              @click="closeMessagePreview($event)"
            >
              <i class="el-icon-close" />
            </div>
            {{ previewBubble.message }}
          </div>
        </el-card>

        <el-card
          v-else-if="$store.getters.webchatUsersIsAtMaxLimit && $store.getters.showMessagePreview"
          :style="{
            color: settings.agentTextColor,
            background: settings.agentBubbleColor,
          }"
          class="keyreply-popup-bubble"
        >
          <div>
            <div
              id="closeButton2"
              style="position: relative; top: -10px; right: -10px; float: right; cursor: pointer"
              @click.prevent="closeMessagePreview($event)"
            >
              <i class="el-icon-close" />
            </div>
            {{
              previewBubble.persistPreviewMessageShown
                ? previewBubble.message
                : previewBubble.maxLimitReachedMessage ||
                  "We're experiencing high traffic. Please try again soon. Thank you for your patience!"
            }}
          </div>
        </el-card>
      </transition>
    </div>

    <chat-window
      ref="chatwindow"
      @launcherclicked="setMouseWheelEvent"
      v-if="chatWindow"
      @handleGrabElement="handleGrabElement"
      :isWidgetReadyToDrag="isElementReadyToDrag"
      :widgetPos="widgetPos"
    />

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="$store.state.lightbox"
      class="iframeDialog iframeDialog__mobile-native"
      :style="$device.isMobileQuery ? { marginTop: '0px !important' } : {}"
      custom-class="el-dialog__mobile-native"
      :lock-scroll="true"
      :before-close="beforeClose"
      @close="lightboxContent = ''"
    >
      <div class="scroll-wrapper scroll-wrapper__mobile-native">
        <iframe :src="lightboxContent" style="width: 100%; height: 100%" frameborder="0" />
      </div>
    </el-dialog>

    <el-drawer
      v-if="isMobileQuery"
      :visible.sync="jwtDrawer.enabled"
      direction="btt"
      :size="'35%'"
      :show-close="false"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <span style="margin-top: 20px" slot="title">
        <el-row type="flex" style="align-items: center">
          <i
            v-if="jwtDrawer.iconType === 'warning'"
            style="font-size: 30px; color: #e6a23c; margin-right: 5px"
            class="el-icon-warning"
          ></i>
          <i
            v-if="jwtDrawer.iconType === 'info'"
            style="font-size: 30px; margin-right: 5px"
            class="el-icon-info"
          ></i>
          {{ jwtDrawer.title }}
        </el-row>
      </span>
      <el-container style="padding: 10px">
        <el-main style="padding-top: 0">
          <span>{{ jwtDrawer.text }}</span>
        </el-main>
        <el-footer>
          <el-row :gutter="10">
            <el-col :xs="12" :sm="{ span: 6, offset: 12 }">
              <el-button
                style="width: 100%"
                v-if="jwtDrawer.cancel"
                :type="buttonOutline"
                plain
                @click="jwtDrawer.enabled = false"
                >Cancel</el-button
              >
            </el-col>
            <el-col
              :xs="jwtDrawer.cancel ? 12 : { span: 12, offset: 12 }"
              :sm="jwtDrawer.cancel ? 6 : { span: 6, offset: 18 }"
            >
              <el-button style="width: 100%" :type="buttonOutline" @click="handleCloseDrawer"
                >Ok</el-button
              >
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </el-drawer>

    <el-dialog
      v-else
      :width="'40%'"
      :visible.sync="jwtDrawer.enabled"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span style="margin-top: 20px" slot="title">
        <el-row type="flex" style="align-items: center">
          <i
            v-if="jwtDrawer.iconType === 'warning'"
            style="font-size: 30px; color: #e6a23c; margin-right: 5px"
            class="el-icon-warning"
          ></i>
          <i
            v-if="jwtDrawer.iconType === 'info'"
            style="font-size: 30px; margin-right: 5px"
            class="el-icon-info"
          ></i>
          {{ jwtDrawer.title }}
        </el-row>
      </span>
      <el-container style="padding: 10px">
        <el-main style="padding-top: 0">
          <span>{{ jwtDrawer.text }}</span>
        </el-main>
      </el-container>
      <span slot="footer">
        <el-row :gutter="10">
          <el-col :xs="12" :sm="{ span: 6, offset: 12 }">
            <el-button
              style="width: 100%"
              v-if="jwtDrawer.cancel"
              :type="buttonOutline"
              plain
              @click="jwtDrawer.enabled = false"
              >Cancel</el-button
            >
          </el-col>
          <el-col
            :xs="jwtDrawer.cancel ? 12 : { span: 12, offset: 12 }"
            :sm="jwtDrawer.cancel ? 6 : { span: 6, offset: 18 }"
          >
            <el-button style="width: 100%" :type="buttonOutline" @click="handleCloseDrawer"
              >Ok</el-button
            >
          </el-col>
        </el-row>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Launcher from "./components/Launcher";
import AppIcon from "./components/AppIcon";
import ChatWindow from "./components/ChatWindow/Index";
import _, { parseInt } from "lodash-es";
import { mapGetters } from "vuex";
import addUnreadMessageBadge from "@/helpers/addUnreadMessageBadge";
import { checkServerIdentity } from "tls";
import getParamsFromUrl from "./helpers/getParamsFromUrl";

export default {
  name: "KeyReply",
  components: {
    Launcher,
    AppIcon,
    ChatWindow,
  },
  data() {
    return {
      id: localStorage._kruid,
      channel: null,
      windowHeight: 1000,
      showIcons: false,
      lightboxContent: "",
      greeted: false,
      t: 0,
      isElementReadyToDrag: false,
      elementOffset: [0, 0],
      dragElementId: null,
      widgetPos: {
        left: null,
        top: null,
      },
    };
  },
  computed: {
    ...mapGetters(["showMessagePreview", "displayLauncher", "isUiBetaEnabled", "isRtlModeEnabled"]),

    /**
     * @description Mobile mode query checking
     * @return boolean
     */
    isMobileQuery() {
      const params = getParamsFromUrl();

      return params.get("mode") === "mobile";
    },
    /**
     * @description Element UI button ouline
     * @return {string}
     */
    buttonOutline() {
      switch (this.settings.buttonOutlineColor) {
        case "red":
          return "danger";
        case "blue":
          return "primary";
        case "green":
          return "success";
        default:
          return "primary";
      }
    },
    /**
     * @description Session expire notification purpose
     * @return object of component;
     *  */
    jwtDrawer() {
      return this.$store.state.jwtDrawer;
    },
    settings() {
      return this.$store.state.settings;
    },
    previewBubble() {
      const previewBubbleSettings = _.get(this.settings, "previewBubble", {
        enabled: false,
      });
      return previewBubbleSettings;
    },
    launcherPosition() {
      return this.settings.position === "left" ? "keyreply-left" : "keyreply-right";
    },
    visibleApps() {
      if (this.showIcons) {
        // Don't show apps with blank
        const mappedVisibleApps = _.map(this.settings.apps, (app, id) => {
          if (app && app.enabled) {
            app.id = id;
            return app;
          } else {
            return null;
          }
        });
        const compactVisibleApps = _.compact(mappedVisibleApps);
        const sortedVisibleAppsByPosition = _.sortBy(compactVisibleApps, "position");
        return sortedVisibleAppsByPosition;
      } else {
        return {};
      }
    },

    showMoreIcon() {
      return this.numberOfApps > this.maxIconCount;
    },
    numberOfApps() {
      return _.filter(this.settings.apps, (app) => app && app.enabled).length;
    },
    maxIconCount() {
      return Math.floor((this.windowHeight - 130) / 52);
    },
    chatWindow() {
      return this.$store.state.chatWindow;
    },
    inLivechatSession() {
      return this.settings.liveChatStatus;
    },
    shouldPromptBeforeNavigateAway() {
      if (!this.settings.promptBeforeNavigateAway) {
        return false;
      } else {
        const chatWindowIsOpened = this.chatWindow;
        const isInLivechatSession = this.settings.liveChatStatus;
        const shouldPromptWhenWidgetOpen =
          this.settings.promptBeforeNavigateAway === "widget_is_opened";
        const shouldPromptDuringLivechat =
          this.settings.promptBeforeNavigateAway === "during_livechat_session";
        return (
          (shouldPromptWhenWidgetOpen && chatWindowIsOpened) ||
          (shouldPromptDuringLivechat && isInLivechatSession)
        );
      }
    },
    elementDOM() {
      return document.getElementById(this.dragElementId);
    },
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("UPDATE_INITIALIZING_DONE");
    }, 5000);
    const params = location.search;
    const startImmediately = params ? !params.includes("manual=true") : true; // Query param doesn't exist

    if (startImmediately) {
      this.$store.dispatch("INITIALIZE");
    }
    this.setupRtlMode(this.isRtlModeEnabled);

    document.getElementById("keyreply-container").onwheel = (e) => {
      e.stopPropagation();
    };

    window.addEventListener("beforeunload", (event) => {
      this.handleWidgetClose(event);
    });

    this.$nextTick(() => {
      this.windowHeight = window.innerHeight;
      window.addEventListener("resize", () => {
        this.windowHeight = window.innerHeight;
      });

      window.addEventListener("online", () => {
        this.$store.commit("UPDATE_ONLINE_STATUS");
      });
      window.addEventListener("offline", () => {
        this.$store.commit("UPDATE_ONLINE_STATUS");
      });
    });

    // Set time out to retry
  },
  watch: {
    displayLauncher(newValue) {
      if (newValue) {
        this.triggerPreviewDelay();
      }
    },
    ["settings.apps.bot"]: {
      handler(after, before) {
        this.launchingAutomatically();
      },
      deep: true,
    },
    isRtlModeEnabled(newValue) {
      this.setupRtlMode(newValue);
    },
  },
  methods: {
    //for notification drawer in chat window
    handleCloseDrawer() {
      this.jwtDrawer.enabled = false;

      this.$store.commit("SET_GREETED", false);
      this.$store.commit("CLEAR_MESSAGES");
      localStorage.clear();

      const drawerAction = _.get(this, "jwtDrawer.action");

      if (drawerAction) {
        drawerAction();
      }
    },
    launchingAutomatically() {
      const params = new URLSearchParams(location.search);
      const webchatUsersIsAtMaxLimit = _.get(this, "$store.getters.webchatUsersIsAtMaxLimit");
      const isNotMobileMode = params.get("mode") !== "mobile";

      if (webchatUsersIsAtMaxLimit || isNotMobileMode) {
        return;
      }

      if (this.checkConditionToLaunchWebchat()) {
        this.$store.dispatch("START");
        this.$store.dispatch("OPEN_CHAT_WINDOW", this.$device.iOSSDK);
      } else {
        this.showIcons = !this.showIcons;
      }

      this.clickedLauncher();
    },
    checkConditionToLaunchWebchat() {
      const numberOfAppsIsOne = this.numberOfApps === 1;
      const settingsAppsBotIsEnabled = _.get(this, "settings.apps.bot.enabled");

      return numberOfAppsIsOne && settingsAppsBotIsEnabled;
    },
    handleWidgetClose(event) {
      if (this.shouldPromptBeforeNavigateAway) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    triggerPreviewDelay() {
      if (this.previewBubble.enabled && !this.showMessagePreview) {
        setTimeout(() => {
          this.$store.commit("UPDATE_SHOW_PREVIEW_BUBBLE", { status: true });
          if (this.previewBubble.hide == 0) {
            return;
          }

          setTimeout(() => {
            this.$store.commit("UPDATE_SHOW_PREVIEW_BUBBLE", { status: false });
          }, this.previewBubble.hide * 1000);
        }, this.previewBubble.delay * 1000);
      }
    },
    // Set the onwheel event when the launcher is clicked
    setMouseWheelEvent() {
      document.getElementById("keyreply-chat-window").onwheel = (e) => {
        const chatBody = document.getElementById("keyreply-panel-body");
        const atTop = chatBody.scrollTop <= 0;
        const atBottom = chatBody.scrollTop + chatBody.offsetHeight >= chatBody.scrollHeight;
        // chatBody.scrollHeight - chatBody.scrollTop === chatBody.clientHeight;
        const scrollDirection = e.deltaY < 0 ? "up" : "down";
        if ((atTop && scrollDirection === "up") || (atBottom && scrollDirection === "down")) {
          e.preventDefault();
          e.stopPropagation();
        }
      };
    },
    closeMessagePreview(e) {
      e.stopPropagation();
      this.$store.commit("UPDATE_SHOW_PREVIEW_BUBBLE", { status: false });
    },
    // FIXME: @Dhoni checking this method is necessary or not.
    clickedLauncher() {
      if (this.settings.apps.length === 1) {
        if (!this.$store.getters.webchatUsersIsAtMaxLimit) {
          this.$store.dispatch("UPDATE_AGENT_TYPING_INDICATOR", false);

          this.$store.commit("OPEN_CHAT_WINDOW");
          this.$store.commit("CLEAR_UNREAD_MESSAGE_COUNT");
          this.$store.dispatch("START");

          this.$nextTick(() => {
            this.$refs.chatwindow.scrollToBottom();
          });
        }
      }
    },
    beforeClose(done) {
      const confirmMessage = this.$createElement(
        "span",
        { style: `font-family:${this.$store.state.settings.font};` },
        "Are you sure you want to close this window?"
      );

      this.$confirm("", "", {
        message: confirmMessage,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "confirm-button-text",
        cancelButtonClass: "cancel-button-text",
      })
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    clickLauncher() {
      this.$refs.launcher.launcherClicked();
    },
    setupRtlMode(isRtlModeEnabled) {
      const htmlEl = document.documentElement;
      if (isRtlModeEnabled) {
        htmlEl.setAttribute("dir", "rtl");
      } else {
        htmlEl.setAttribute("dir", "ltr");
      }
    },
    handleGrabElement({ event, elementId }) {
      if ((this.isUiBetaEnabled && !this.$device.Mobile) || elementId === "keyreply-launcher") {
        let clientX = event.clientX;
        let clientY = event.clientY;

        if (event.type === "touchstart") {
          clientX = event.touches[0].clientX;
          clientY = event.touches[0].clientY;
        }

        this.dragElementId = elementId;
        this.elementDOM.style.transition = "all 0s";
        this.elementOffset = [
          clientX - this.elementDOM.offsetLeft,
          clientY - this.elementDOM.offsetTop,
        ];
        if (event.type === "touchstart") {
          document.ontouchmove = this.handleDragElement;
          document.ontouchend = this.handleStopGrabElement;
        } else {
          document.onmousemove = this.handleDragElement;
          document.onmouseup = this.handleStopGrabElement;
        }
      }
    },
    handleDragElement(e) {
      if (!this.isElementReadyToDrag) {
        this.isElementReadyToDrag = true;
      }

      if (!this.elementDOM.style.right || !this.elementDOM.style.bottom) {
        this.elementDOM.style.right = "auto";
        this.elementDOM.style.bottom = "auto";
      }

      if (this.isElementReadyToDrag) {
        let isTouchEvent = e.type?.startsWith("touch");
        const clientX = isTouchEvent ? e.touches[0].clientX : e.clientX;
        const clientY = isTouchEvent ? e.touches[0].clientY : e.clientY;
        this.elementDOM.style.left = clientX - this.elementOffset[0] + "px";
        this.elementDOM.style.top = clientY - this.elementOffset[1] + "px";
      }
    },
    handleStopGrabElement() {
      const elementStyle = window.getComputedStyle(this.elementDOM);
      const elementLeftPosition = parseInt(elementStyle.left);
      const elementTopPosition = parseInt(elementStyle.top);
      const windowWidth = window.innerWidth - parseInt(elementStyle.width);
      const windowHeight = window.innerHeight - parseInt(elementStyle.height);
      this.elementDOM.style.transition = "all 0.5s";

      let fixedLeft;
      let fixedTop;

      if (elementLeftPosition > windowWidth) {
        fixedLeft = this.dragElementId === "keyreply-launcher" ? windowWidth - 25 : windowWidth;
      }
      if (elementLeftPosition < 0) {
        fixedLeft = this.dragElementId === "keyreply-launcher" ? `10` : `0`;
      }
      if (elementTopPosition < 0) {
        fixedTop = this.dragElementId === "keyreply-launcher" ? `10` : `0`;
      }
      if (elementTopPosition > windowHeight) {
        fixedTop = this.dragElementId === "keyreply-launcher" ? windowHeight - 25 : windowHeight;
      }

      this.elementDOM.style.left = fixedLeft + "px";
      this.elementDOM.style.top = fixedTop + "px";

      document.ontouchmove = null;
      document.ontouchend = null;
      document.onmousemove = null;
      document.onmouseup = null;

      setTimeout(() => {
        this.isElementReadyToDrag = false;
      }, 0);

      if (this.dragElementId === "keyreply-launcher" && !this.$device.Mobile) {
        this.changeWidgetPosition(windowWidth, windowHeight);
      }
    },
    changeWidgetPosition(windowWidth, windowHeight) {
      const iconLeftPos = parseInt(this.elementDOM.style.left);
      const iconTopPos = parseInt(this.elementDOM.style.top);
      const iconElement = document.getElementById("keyreply-launcher");
      const widgetElement = document.getElementById("keyreply-chat-window");
      const widgetWidth = this.settings.window === "wide" ? 550 : 420;
      const widgetHeight = 550;
      let widgetTopPos = iconTopPos - 500;
      let widgetLeftPos;

      if (widgetElement) {
        widgetElement.style.transition = "all 0.5s";
      }

      if (iconLeftPos > windowWidth / 2) {
        widgetLeftPos = iconLeftPos - widgetWidth + 10;
      } else if (iconLeftPos < windowWidth / 2) {
        widgetLeftPos = iconLeftPos + iconElement.offsetWidth + 10;
      }

      if (widgetTopPos < 0) {
        widgetTopPos = 10;
      } else if (widgetTopPos > windowHeight - widgetHeight) {
        widgetTopPos = windowHeight - (widgetHeight + 15);
      }

      this.widgetPos.left = widgetLeftPos;
      this.widgetPos.top = widgetTopPos;
    },
  },
};
</script>

<style lang="scss">
$font-stack: "ProximaNova", Avenir, "Tahoma", "Avenir Next", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";

@media only screen and (max-width: 768px) {
  .el-message-box {
    width: 420px;
  }

  .el-message-box__content {
    font-size: 20px;
  }

  .confirm-button-text {
    font-size: 20px;
  }

  .cancel-button-text {
    font-size: 20px;
  }

  .cbio_session_controls {
    bottom: 60px !important;
  }
}

@media only screen and (max-width: 414px) {
  .el-message-box {
    width: 300px;
  }

  .el-message-box__content {
    font-size: 14px;
  }

  .confirm-button-text {
    font-size: 12px;
  }

  .cancel-button-text {
    font-size: 12px;
  }
}

#keyreply-container {
  font-family: $font-stack;
  font-weight: 400;
  z-index: 99999;

  .keyreply-launcher-wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 100;
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    pointer-events: none;
  }

  &.keyreply-left .keyreply-launcher-wrapper {
    right: auto;
    left: 0;
  }

  &.keyreply-left .keyreply-launcher-wrapper .keyreply-launcher,
  &.keyreply-left .keyreply-launcher-wrapper .keyreply-chat-icon {
    right: auto;
    left: 20px;
  }

  &.keyreply-left .keyreply-launcher-wrapper .keyreply-chat-icon.keyreply-panel {
    left: auto;
  }
  //HACKY WAY TO PREVENT OVERRIDING TEXTAREA HEIGHT
  .keyreply-message-input {
    textarea {
      height: 38px;
      min-height: 38px;
      max-height: 200px;
      overflow-y: auto;
      resize: none;
      background: #fff;
    }
  }
}

.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

.keyreply-popup-bubble {
  width: 280px;
  border-radius: 10px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  pointer-events: auto;
  position: absolute;
  bottom: 100%;
  margin: 0 20px;

  @media only screen and (max-width: 330px) {
    width: 180px;
    height: 80px;
    font-size: small;
    right: 80px;
  }

  @media only screen and (min-width: 360px) {
    width: 230px;
  }
}

.el-dropdown-menu__item {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "微软雅黑", Arial, sans-serif;
}

.el-dialog {
  position: relative;
  margin-top: 6vh !important;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 70%;
  margin-top: 3vh !important;

  @media only screen and (max-width: 768px) {
    top: 1vh !important;
    bottom: 1vh !important;
    height: 100%;
    width: 90% !important;
  }

  @media only screen and (max-width: 450px) {
    margin-top: 6vh !important;
    width: 100% !important;
  }
}

.el-dialog__mobile-native {
  @media only screen and (max-width: 450px) {
    top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.iframeDialog__mobile-native {
  @media only screen and (max-width: 450px) {
    top: 0px !important;
    height: 100% !important;
  }
}

.iframeDialog {
  margin-top: 10px !important;
  width: 80% !important;

  @media only screen and (max-width: 1024px) {
    width: 100% !important;
    height: 90%;
    overflow: visible;
  }
}

.scroll-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  height: 80vh;
  width: 100%;
}

.scroll-wrapper__mobile-native {
  @media only screen and (max-width: 450px) {
    height: calc(100vh - 90px);
  }
}

/* Media Query */

#keyreply-container img {
  max-width: 100%;
  max-height: 95vh;
}

.has-cursor-pointer {
  cursor: pointer;
}

.is-mobile-hidden {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

@media print {
  #keyreply-container {
    visibility: hidden;
  }
}
</style>

<style lang="scss">
[dir="ltr"] #keyreply-container {
  .keyreply-launcher-wrapper {
    right: 0;
  }

  &.keyreply-left .keyreply-launcher-wrapper {
    right: auto;
    left: 0;
  }

  &.keyreply-left .keyreply-launcher-wrapper .keyreply-launcher,
  &.keyreply-left .keyreply-launcher-wrapper .keyreply-chat-icon {
    right: auto;
    left: 20px;
  }

  &.keyreply-left .keyreply-launcher-wrapper .keyreply-chat-icon.keyreply-panel {
    left: auto;
  }
}

[dir="rtl"] #keyreply-container {
  .keyreply-launcher-wrapper {
    left: 0;
  }

  &.keyreply-left .keyreply-launcher-wrapper {
    left: auto;
    right: 0;
  }

  &.keyreply-left .keyreply-launcher-wrapper .keyreply-launcher,
  &.keyreply-left .keyreply-launcher-wrapper .keyreply-chat-icon {
    left: auto;
    right: 20px;
  }

  &.keyreply-left .keyreply-launcher-wrapper .keyreply-chat-icon.keyreply-panel {
    right: auto;
  }
}

[dir="ltr"] .text-left {
  text-align: left;
}

[dir="rtl"] .text-left {
  text-align: right;
}

[dir="ltr"] .text-right {
  text-align: right;
}

[dir="rtl"] .text-right {
  text-align: left;
}

[dir="ltr"] .mr-5 {
  margin-right: 5px;
}

[dir="rtl"] .mr-5 {
  margin-left: 5px;
}

[dir="ltr"] .mr-12 {
  margin-right: 12px;
}

[dir="rtl"] .mr-12 {
  margin-left: 12px;
}

[dir="ltr"] .mr-36 {
  margin-right: 36px;
}

[dir="rtl"] .mr-36 {
  margin-left: 36px;
}

[dir="ltr"] .m-0-5-10-n10 {
  margin: 0 5px 10px -10px;
}

[dir="rtl"] .m-0-5-10-n10 {
  margin: 0 -10px 10px 5px;
}

[dir="ltr"] .right-5p {
  right: 5%;
}

[dir="rtl"] .right-5p {
  left: 5%;
}

[dir="ltr"] .left-5p {
  left: 5%;
}

[dir="rtl"] .left-5p {
  right: 5%;
}

[dir="ltr"] .float-right {
  float: right;
}

[dir="rtl"] .float-right {
  float: left;
}

[dir="ltr"] .right-n10 {
  right: -10px;
}

[dir="rtl"] .right-n10 {
  left: -10px;
}

[dir="ltr"] .right-0 {
  right: 0;
}

[dir="rtl"] .right-0 {
  left: 0;
}

[dir="ltr"] .right-130 {
  right: 130px;
}

[dir="rtl"] .right-130 {
  left: 130px;
}

[dir="ltr"] .right-100 {
  right: 100px;
}

[dir="rtl"] .right-100 {
  left: 100px;
}
</style>
